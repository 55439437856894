/**
 * Type: ページ
 * What: 王貞治ヒストリーゾーン（中学～選手時代）
 */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import SimpleSwiper from '../../../components/Slider.fade';
import Image from '../../../util/Image';
import { Button } from '../../../components/Btn';

const pageTitle = '王貞治ヒストリーゾーン（中学～選手時代｜王貞治ベースボールミュージアム Supported by DREAM ORDER)';
const pageDescription = '幼少時代から選手時代まで、さまざまな最新の展示手法を使いながら野球人・王貞治を描きます。';
const pageSlug = 'obm';
const pageLogo = 'logo-obm-single.png';

const sliderDataobj = [
  {
    filename: 'main-history-player.jpg',
  },
];

// Data Props Template
const Template = () => (
  <Layout pageSlug={pageSlug} bg="bg-4">
    <SEO
      title={pageTitle}
      description={pageDescription}
    />
    <BreadcrumbComponent
      hierarchy={4}
      bread2url="obm/"
      bread2title="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
      bread3url="obm/museum/"
      bread3title="王貞治ヒストリーゾーン"
      bread4title={pageTitle}
    />
    <section className="pageMain bg-12 bs-4">
      <div className="kv-wrap bg-4">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-4">
                <span>4</span>
                <span>F</span>
              </div>
              <h3 className="ttl">
                <span className="logo-under ml-0">
                  <span className='ml-0'>
                    <b className="is-1">王貞治ヒストリーゾーン</b>
                    <small className='tac'>（幼少〜選手時代）</small>
                  </span>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section sc-museum bg-12" id="museum">
      <div className="container">
        <div className="inner">
          <h3 className="headline">
            <span>History Zone</span>
          </h3>
          <div className="sc-columns">
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm05.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">中学・高校時代</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm06.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">巨人軍入団</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm07.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">荒川道場秘話</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm08.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">1本足打法の分析</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm09.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">ホームランでたどる王貞治ヒストリー</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm10.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">データで見る王貞治</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-6 floorbc4">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-inner">
                  <div className="col-image">
                    <figure className="image">
                      <Image filename="img-obm11.png" />
                    </figure>

                  </div>
                  <div className="col-content">
                    <h3 className="ttl c-4 has-text-align-center">友人、仲間、交流した人々</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-wrap">
        <Button
          href="/obm/museum/"
        >
          一覧へ戻る
        </Button>
      </div>
    </section>
  </Layout>
);

export default Template;
